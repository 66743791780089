.login-container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;

    .login {
        flex: 1 1 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        h2 {
            flex: 1 1 100%;
            text-align: center;
            color: white;
        }

        form {
            flex: 1 1 100%;
        }
    }
}
