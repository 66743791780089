// Colors
$text-primary: #f2f2f2;
$text-dark: "black";
$text-light: "white";
$text-secondary: #f2f2f2;
$bg-primary: #4C4A4A;
$bg-secondary: #000000;

// Breakpoints
$phone: 320px;
$tablet: 767px;
$tablet-lrg: 1023px;
$laptop: 1365px;
$desktop: 1919px;

// Gutters
$desktop-gutter: 100px;
$mobile-gutter: 1.5rem;

// Z Indexes
$zIndex-content-overlay: 990;

// Spacings
$spacingParagraph: 1.5rem;
$spacingParagraphMobile: 1.2rem;
$spacingParagraphDouble: 4rem;
$spacingModal: 100px;
$spacingModalMobile: 40px;
$spacingBlock: 130px;
$mobileGutter: 2rem;
$desktopGutter: 9.5rem;

// Block Settings
$blockHeight: 800px;
$blockMaxHeading: 400px;
$blockMaxParagraph: 500px;
$blockImgMaxHeight: 600px;
$blockImgMaxHeightMobile: 400px;
$blockMobileTopBottomGutter: 50px;
$blockMinBackgroundHeight: 365px;
