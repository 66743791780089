@import "../../includes/settings.scss";
.mobile-menu {
    position: fixed;
    z-index: 25;
    right: 0;

    .mobile-links-container {
        display: none;
        position: fixed;
        z-index: 1;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        text-align: left;
        top: 0;
        left: 100vw;
        transition: left 0.3s ease-in-out;
        background: $bg-secondary;

        @media (max-width: $tablet-lrg) {
            display: flex;
            width: 100%;
        }

        &.open {
            left: 0;
        }

        .mobile-links-centering-container {
            justify-content: center;
        }

        .navlink-div {
            flex: 0 1 auto;
            justify-content: center;
            flex-direction: column;

            .navlinks-mobile {
                li {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding: 1rem 0;
                    font-weight: 600;
                    color: $text-primary;
                    text-decoration: none;
                    list-style: none;

                    @media (max-width: $tablet-lrg) {
                        font-size: 1rem;
                    }

                    .red-dot {
                        position: absolute;
                        left: 48.5%;
                        top: 39%;
                        opacity: 0;
                        z-index: -1;
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover {
                        text-transform: underline;
                    }
                }
            }
        }
    }

    .mobile-contact-icons-container {
        flex: 0 1 auto;

        .contact-icons-container {
            padding-top: 8%;
            justify-content: center !important;

            ul {
                justify-content: center;

                li {
                    padding: 0 20% !important;
                    .red-dot {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 3rem;
                        width: 3rem;
                    }

                    .icon-image {
                        position: relative;
                        height: 2.5rem;
                        width: 2.5rem;
                    }
                }
            }
        }
    }
}
