.admin-form-container {
    flex: 1 1 100%;

    // Colors
    $primary: #d5ebe9;
    $secondary: #F58123;
    $text-light: white;
    $text-dark: #231f20;
    $orange: #eb7729;
    $orange-alt: #ee7623;
    $pink: #E885BF;
    $black: #231f20;
    $grey: #d3d4d4;
    $light-grey: #F1F2F2;

    // Variables
    $spacing-xs: 2rem;
    $spacing-small: 50px;
    $spacing-large: 70px;
    $spacing-block: 120px;

    $tootlip-size: 200px;

    $mobile-cut-off: 1024px;
    $max-viewport: 1760px;

    $border-radius: 5px;
    $background-color: #ffffff;

    form {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .field-group {
            display: flex;
            flex-direction: column;
            margin: 0 7.5px;
            flex: 1 1 0;
            align-items: flex-start;

            label {
                flex: 1 1 auto;
                // height: calc(2rem + 10px);
                font-size: 14px;
                line-height: 28px;
            }
            
            .form-input-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                a {
                    flex: 1 1 auto;
                    display: inline-flex;
                    width: 100%;

                    svg {
                        min-height: 2rem;
                        min-width: 2rem;
                    }

                    .link-text {
                        display: inline;
                        margin-left: 1rem;
                    }

                    .fa-primary {
                        color: $text-dark;
                    }

                    &:hover {
                        .link-text {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .uploaded_images {
                margin-top: 1rem;

                .item {
                    text-align: center;

                    img {
                        max-height: 150px;
                    }
                }
            }
        }
    }

    .rating-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0.5rem 1rem;

        label {
            flex-basis: 100%;
        }

        .field-group.checkbox {
            .form-input-container {
                display: flex;
                flex-direction: column;

                input[type=radio] {
                    flex: 1 1 auto;
                    height: 2rem;
                    width: 2rem;
                    padding: unset;
                    appearance: none;
                    background-color: $primary;
                    border: 1px solid $primary;
                    border-radius: 50%;
                    background-clip: content-box;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                
                    &:checked {
                        background-color: unset;
                        border: unset;
                    }
                
                    &:disabled {
                        opacity: 0.3;
                    }
                
                    &:focus {
                        outline: 0;
                    }
                }

                label {
                    font-size: 1rem;
                    font-weight: normal;
                }
            }
        }
        
    }

    .field-group.radio {
        justify-content: center;
        text-align: center;

        .item {
            text-align: left;
        }

        .item-equal {
            display: flex;
            align-items: center;

            input {
                flex: 0 1 auto;
            }

            label {
                text-align: left;
                padding-left: 1rem;
            }
        }
    }

    label {
        margin: 5px 10px 5px 0;
        flex: 0 0 100%;
    }

    input {
        width: 100%;
        font-family: inherit;
        padding: 1rem .75rem;
        flex: 0 0 100%;
        box-sizing: border-box;
        border: 1px solid $text-dark;
        border-radius: $border-radius;
        background-color: $background-color;
        margin: 5px 0px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        // max-block-size: 58px;

        &:disabled {
           // opacity: 0.3;
        }

        &:focus {
            outline: 0;
        }
    }

    input[type=radio] {
        height: 2rem;
        width: 2rem;
        padding: unset;
        appearance: none;
        background-color: $primary;
        border: 8px solid $primary;
        border-radius: 50%;
        background-clip: content-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked {
            background-color: $pink;
        }

        &:disabled {
            // opacity: 0.3;
        }

        &:focus {
            outline: 0;
        }
    }

    input[type=checkbox] {
        height: 2rem;
        min-width: 2rem;
        padding: unset;
        margin: unset;
        flex: unset;
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 50%;
        background-clip: content-box;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked {
            background-color: $pink;
            border: 1px solid $pink;
        }

        &:disabled {
            // opacity: 0.3;
        }

        &:focus {
            outline: 0;
        }
    }

    select {
        font-family: inherit;
        padding: 1rem .75rem;
        flex: 1 1 100%;
        box-sizing: border-box;
        border: 1px solid $text-dark;
        border-radius: $border-radius;
        background-color: $background-color;
        margin: 3px 0px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:disabled {
            opacity: 0.3;
        }

        &:focus {
            outline: 0;
        }
    }

    textarea {
        width: 100%;
        font-family: inherit;
        padding: 1rem .75rem;
        flex: 0 0 100%;
        box-sizing: border-box;
        border: 1px solid $text-dark;
        border-radius: $border-radius;
        margin: 5px 0px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:disabled {
            // opacity: 0.3;
        }

        &:focus {
            outline: 0;
        }
    }

    button[type=submit] {
        width: 99.5%;
        align-self: center;

        &:disabled {
            opacity: 0.1;
        }
    }

    label.required:after {
        content:" *";
        color: #DE274F;
    }

    .form-error {
        color: #c72c41;
        min-height: 25px;
        font-size: 14px;
        line-height: 28px;
    }

    .form-warning {
        color: #ffdc34;
    }

    // Toggle Checkbox
    .field-group.toggle {
        block-size: 90px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 2.3rem 1rem 0rem 1rem;

        label {
            max-block-size: 100%;

            span {
                margin-right: 3rem;
            }
        }
    }

    .field-group.checkbox {
        .form-input-container {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            display: flex;

            label {
                flex: 0 1 auto;
            }
        }
    }
}


