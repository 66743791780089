@import "../../includes/settings.scss";

.gallery-container-container {
    flex: 1 1 100%;
    flex-direction: column;
    padding: 0 $desktop-gutter;
    align-items: center;

    .content-container {
        padding: 10rem 0;

        .title-container,
        .subtitle-container {
            flex: 1 1 100%;
            justify-content: center;
            text-align: center;

            p {
                text-align: center;
                max-width: 40rem;
                padding: 2rem 0 4rem 0;
            }
        }

        .gallery-container {
            flex-direction: row;
        }
    }
}

@media only screen and (max-width: $tablet-lrg) {
    .gallery-container-container {
        padding: 0 $mobile-gutter;
    }
}
