@import "../../includes/settings.scss";

.social-container {
    flex-direction: row;
    padding: 3rem 0 1rem 0;

    .join-us-container {
        justify-content: center;
        color: white;

        h2 {
            text-align: center;
        }
    }
    .social-list {
        flex: 1 1 100%;
        list-style: none;
        display: flex;

        li {
            margin: 0 0.75rem;
            display: flex;
            position: relative;
            display: flex;
            justify-content: center;
            font-weight: bold;
            color: $text-primary;
            text-decoration: none;
            list-style: none;

            @media (max-width: $tablet-lrg) {
                font-size: 1.5rem;
            }
        }
    }

    .social {
        &:hover {
            .social-svg {
                fill: #04314d;
            }
        }
    }
}

@media only screen and (max-width: $tablet) {
    .social-container {
        justify-content: center;

        ul {
            flex-direction: row;
            justify-content: center;

            li {
            }
        }

        .join-us-container {
            h2 {
                font-size: 1.5rem;
            }
        }
    }
}
