@import "../../includes/settings.scss";

.header-container {
    justify-content: center;
    .header {
        flex: 1 1 100%;
        flex-wrap: nowrap;

        .title-container {
            align-content: center;
            justify-content: flex-end;

            h1 {
                text-orientation: sideways;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                padding: 0 0.5rem;
            }
        }

        .header-image-container {
            position: relative;
            flex: 1 1 87.5%;
            justify-content: center;
            align-items: center;

            .header-svg {
                z-index: 5;
            }

            .header-svg {
                position: absolute;
            }

            picture {
                flex: 1 1 80%;
            }

            @media only screen and (max-width: $tablet) {
                .header-svg {
                    max-height: 12.5rem;
                }
            }
        }
    }

    @media only screen and (max-width: $tablet-lrg) {
        padding-top: 3.5rem;
    }
}

@media only screen and (min-width: $tablet-lrg) and (max-width: 1600px) {
    .header-container {
        .header {
            .title-container {
                h1 {
                    font-size: 3rem;
                }
            }
        }
    }
}
