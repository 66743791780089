@import "../../includes/settings.scss";

.vertical-para-container {
    flex: 1 1 100%;
    justify-content: center;
    overflow: hidden;
    scroll-margin-top: 5rem;

    .content-container {
        padding: 10rem 0;
        justify-content: center;

        .top-container {
            flex: 1 1 100%;
            justify-content: center;

            .title-container {
                flex: 1 1 100%;
                justify-content: center;
                align-items: center;
                padding: 0 0.5rem;
            }

            .image-container {
                max-width: 37.5rem;
            }
        }

        .body-container {
            max-width: 40.625rem;
            margin: 1rem 2rem;

            p {
                flex: 1 1 100%;
                max-width: 40.625rem;
                text-align: center;
            }

            .btn-primary {
                margin: 0;
                padding: 0;
            }
        }
    }

    @media only screen and (max-width: $tablet) {
        .body-container {
            margin: 0;
            max-width: unset;
            padding: 1rem 1.125rem 0 1.125rem;

            p {
                font-size: 0.8125rem;
            }
        }
    }

    @media only screen and (max-width: $tablet-lrg) {
        .top-container {
            flex-wrap: nowrap;
        }
    }
}
