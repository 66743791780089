@import "../../includes/settings.scss";

.Users-List-Container {
    flex: 1 1 100%;
    min-height: 100vh;

    .users-navbar {
        display: flex;
        flex: 1 1 100%;
        justify-content: flex-end;

        h2 {
            flex: 1 1 auto;
            text-align: center;
            margin: 1rem 0;
        }

        ul {
            list-style: none;
            margin: 1rem 0;

            li {
                margin: 0 0.5rem;
            }
        }
    }

    .users-list {
        display: block;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        h2 {
            text-align: center;
            padding: 1rem;
        }

        .variables-container {
            max-width: 99vw;
            max-height: 85vh;
            overflow: auto;

            table {
                width: 100%;
                margin-bottom: 1rem;
                color: $text-primary;
                border-collapse: collapse;

                th,
                td {
                    padding: 0.75rem;
                    vertical-align: top;
                    border-top: 1px solid #dee2e6;
                    text-align: center;
                    max-width: 400px;
                }

                thead {
                    th {
                        vertical-align: bottom;
                        border-bottom: 2px solid #dee2e6;
                    }
                }

                tbody {
                    tr:hover {
                        background-color: rgba(0, 0, 0, 0.15) !important;
                    }

                    tr:nth-of-type(odd) {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }
            }
        }
    }
}

.List-Container {
    flex: 1 1 100%;

    .list-header {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;

        h1 {
            text-align: center;
            padding: 1rem;
        }
    }

    .list {
        display: block;
        width: 100%;
        // overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        table {
            width: 100%;
            margin-bottom: 1rem;
            color: $text-primary;
            border-collapse: collapse;

            th,
            td {
                padding: 0.75rem;
                vertical-align: middle;
                border-top: 1px solid #dee2e6;
                text-align: center;
            }

            thead {
                th {
                    vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
                }
            }

            tbody {
                tr:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                }
            }

            tr {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }
            }
        }
        .pagination {
            flex: 0 1 auto;

            div{
                flex: 0 1 auto;
            }
        }
    }
}
