@import "../../includes/settings.scss";

.contact-container {
    justify-content: center;
    flex: 1 1 100%;
    min-height: 35rem;
    overflow: hidden;

    .content-container {
        padding: 10rem 0;
        justify-content: center;

        .contact-title {
            flex: 1 1 100%;
            justify-content: center;
            align-content: center;
        }

        .contact {
            justify-content: center;
            max-width: 43.5rem;
            align-items: flex-start;

            label {
                display: none;
            }
        }

        .contact-form {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            max-width: 31.25rem;

            input,
            textarea {
                width: 100%;
                font-size: 1rem;
                color: #f2f2f2;
                background-color: transparent;
                border-width: 0;
                border: 3px solid;
                border-radius: 0;
                padding: 0.25rem 2rem;

                &::placeholder,
                &::-webkit-input-placeholder {
                    font-size: 1rem;
                    opacity: 0.4;
                    color: #f2f2f2;
                }
            }

            .button-container {
                justify-content: center;
            }
        }
    }

    @media only screen and (max-width: $tablet-lrg) {
        flex-wrap: nowrap;

        .content-container {
            padding: 4.625rem 0 ;
        }

        .contact-title {
            margin: 0 1rem;
            padding: 0;
        }

        .contact-title {
            flex: 0 1 5%;
        }
    }
}
