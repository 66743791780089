@import "../../includes/settings.scss";

.testimonials-container {
    padding: 0 !important;
    flex: 1 1 100%;
    width: 100%;
    background: transparent !important;

    img {
        border-radius: 50%;
    }
    div {
        display: flex;
        position: unset;
        flex-wrap: unset;
        justify-content: unset;
        align-items: unset;
        align-content: unset;
    }
    & > :first-child {
        width: 100%;
    }

    div {
        flex: 1 1 100%;

        .testimonial_google-container {
            flex: 1 1 100%;
            padding: 6.5rem 10%;
            flex-direction: column-reverse;

            .lazyload-wrapper {
                justify-content: center;
            }
            .left {
                .content {
                    padding: 0;
                    .outline {
                        display: none;
                        border: 7px solid $text-primary;
                    }
                    .rectangle {
                        display: none;
                    }
                    .card {
                        justify-content: center;
                        flex-direction: row;
                        padding: 0;
                        min-width: 350px;
                        border-radius: 0;
                        box-shadow: unset;
                        background-color: transparent;
                        max-width: 40.625rem;
                        min-height: 0px;

                        .header {
                            .name {
                                padding: 1rem 0 0 0;

                                h3 {
                                    text-align: center;
                                    font-style: italic;
                                    font-weight: 400;
                                    color: $text-primary;
                                    text-transform: capitalize;
                                }
                            }
                        }
                        .body {
                            justify-content: center;
                            flex: 0 1 auto;
                            p {
                                line-height: 1.628;
                                text-align: center;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 10;
                                -webkit-box-orient: vertical;
                            }
                        }
                        .date {
                            display: none;
                        }
                    }
                    .previous-container,
                    .next-container {
                        img {
                            object-fit: contain;
                        }
                        button {
                            box-shadow: unset;
                            background-color: transparent;
                        }
                    }
                }
            }
            .right {
                padding-bottom: 1rem;
                justify-content: center;

                .content {
                    align-items: center;
                    p {
                        text-align: center;
                    }
                }
            }
            button {
                cursor: pointer;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .testimonials-container {
        flex: 1 1 100%;

        div {
            flex: 1 1 100%;

            .testimonial_google-container {
                flex: 1 1 100%;
                flex-wrap: wrap-reverse;
                padding: 6.5rem 0;

                .right {
                    .content {
                        align-items: center;
                        h1 {
                            font-size: 1.5rem;
                        }

                        h2 {
                            font-size: 35px;
                        }
                        p {
                            text-align: center;
                        }
                    }
                }

                .left {
                    .content {
                        .card {
                            min-width: unset;
                        }
                        .outline {
                            display: none !important;
                        }
                        .rectangle {
                            top: 2rem;
                            left: 2rem;
                            height: 250px;
                            width: 240px;
                        }
                        .previous-container,
                        .next-container {
                            button {
                                margin: 0;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .testimonials-container {
        div {
            .testimonial_google-container {
                flex-wrap: wrap-reverse;

                .right {
                    .content {
                        align-items: center;

                        h2 {
                            font-size: 35px;
                        }
                        p {
                            text-align: center;
                        }
                    }
                }

                .left {

                    .content {
                        .outline {
                            display: flex;
                            left: -1rem;
                            width: 330px;
                        }
                        .rectangle {
                            top: 2rem;
                            left: calc((50% - (340px / 2)) - 50px);
                            height: 351px;
                            width: 340px;
                        }
                    }
                }
            }
        }
    }
}

// Tablet Landscape
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .testimonials-container div .testimonial_google-container .left .content {
        .outline {
            left: 1rem;
        }
        .rectangle {
            left: 3rem;
            top: 2rem;
        }
    }
}
// Tablet Pro
@media only screen and (min-device-width: $desktop) and (max-device-width: 1366px) {
    .testimonials-container {
        div {
            .testimonial_google-container {
                .left {
                    .content {
                        .outline {
                            left: 1rem;
                        }
                        .rectangle {
                            left: 3rem;
                            top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

/* IPad Pro Landscape */
@media only screen and (min-width: $desktop) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .testimonials-container
        div
        .testimonial_google-container
        .left
        .content
        .rectangle {
        left: 7rem;
    }
}

// Small Laptop/IPad Pro Landscape
@media only screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
    .testimonials-container {
        div {
            .testimonial_google-container {
                .left {
                    .content {
                        .outline {
                            left: calc((50% - (370px / 2) - 60px));
                        }
                        .rectangle {
                            top: 2rem;
                            left: calc((50% - (340px / 2)) - 50px);
                        }
                    }
                }
            }
        }
    }
}
//XS Phone
@media only screen and (max-device-width: 321px) {
    .testimonials-container {
        div {
            .testimonial_google-container {
                .left {
                    .content {
                        .rectangle {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
