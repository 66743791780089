@import "../../includes/settings.scss";

.navbar-container {
    z-index: 1000;
    position: fixed;
    justify-content: center;
    flex: 1 1 100%;
    width: 100%;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

    //  &.-primary {
    //      background: rgba(0, 0, 0, 0.7);
    //      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    //  }

    .navbar {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;

        .logo-container {
            position: absolute;
            z-index: 30;
            max-height: 5.5rem;
            max-width: 7.2rem;

            a {
                display: flex;
            }
        }

        .navbar-right {
            flex-direction: column;

            @media (max-width: $tablet-lrg) {
                display: none;
            }
        }

        .navbar-links-container {
            justify-content: center;

            ul {
                display: flex;
                flex-direction: row;
                list-style-type: none;
                padding: 0.6rem 0;

                li {
                    padding-left: 2rem;
                    position: relative;
                    color: $text-primary;
                    text-transform: uppercase;

                    .navbar-link {
                        font-weight: 600;
                        transition: all 0.2s ease-in-out;

                        &.-primary {
                            color: $text-primary;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $tablet-lrg) {
        background: black;

        .navbar {
            padding: 1.75rem 1.75rem 1.75rem 1rem;
            .logo-container {
                max-height: 5.5rem;
                max-width: 3.5rem;
            }
        }
    }

    @media only screen and (min-width: $tablet-lrg) and (max-width: 1600px) {
        .navbar {
            .logo-container {
                visibility: hidden;
            }
        }
    }

    @media only screen and (min-width: $desktop) {
        .navbar {
            max-width: 120rem;
        }
    }
}
