@import "../../includes/settings.scss";

.footer-container {
    color: $text-secondary;
    justify-content: center;
    padding: 2rem 0;
    overflow: hidden;

    .content-container {
        justify-content: center;
        max-width: 57rem;

        .footer-social-container {
            flex: 0 1 auto;
            padding: 0 6.25rem;

            ul {
                justify-content: center;
                align-items: center;

                li {
                    padding: 1.125rem 0;
                }
            }
        }

        .icon-container {
            flex: 1 1 0;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .signature {
        justify-content: center;
        flex: 1 1 100%;
    }

    @media only screen and (max-width: $tablet) {
        flex-direction: column;
        flex-wrap: nowrap;
        max-width: 100%;

        .content-container {
            .contact {
                flex: 1 1 100%;
                align-items: center;

                p {
                    text-align: center;
                }
            }

            .footer-social-container {
                flex: 1 1 100%;
            }

            .icon-container {
                justify-content: center;
                flex: 1 1 100%;

                .footer-svg {
                    width: 4.75rem;
                }
            }
        }
    }
}
