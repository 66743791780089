@import "../../includes/settings.scss";

.hamburger {
    position: relative;
    margin: auto 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 22.89px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    @media (max-width: $tablet-lrg) {
        display: flex;
    }

    &:focus {
        outline: none;
    }

    div {
        width: 22.89px;
        height: 2px;
        background: $text-primary;
        border-radius: 0.72rem;
        transition: all 0.3s ease-in-out;
        position: relative;
        transform-origin: 1px;
        flex-grow: initial;

        &:first-child {
            transform: rotate(0);
        }

        &:nth-child(2) {
            opacity: 1;
        }

        &:nth-child(3) {
            transform: rotate(0);
        }

        &.open {
            &:first-child {
                width: 22px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                width: 22px;
                transform: rotate(-45deg);
            }
        }
    }
}
