@import "./styles/includes/settings.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.loading-screen-container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 1s ease-in-out;
    background: white;

    &.available {
        opacity: 0;
    }

    .gif-container {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: white;
        z-index: 10000;
    }

    #loading-gif {
        max-width: 12rem;
        z-index: 10001;
    }
}

:root {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    --text-primary: #ffffff;
    --text-secondary: #ffffff;
    --bg-primary: #ffffff;
    --bg-secondary: #0681bf;
    --transition-speed: 200ms;
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

//Prevents background shapes SVG from flowing past the last element
.home-page-container,
.fees-page-container,
.services-page-container,
.contact-page-container {
    overflow: hidden;
}

//Default div styles affected swal, fixed here
.swal2-popup {
    flex-grow: 0;
}

body {
    color: $text-primary;
    background-color: $bg-primary;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        flex: 1 1 auto;
    }

    li,
    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: unset;
        margin: unset;
    }

    h1 {
        font-size: 2.25rem;
        font-weight: 600;
    }

    h2 {
        font-size: 2.25rem;
        font-weight: 600;
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 700;
    }

    h4 {
        font-size: 1.125rem;
        font-weight: 400;
    }

    h5 {
        font-size: 1.125rem;
        font-weight: 500;
    }

    h6 {
        font-size: 1rem;
        font-weight: 500;
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        color: $text-primary;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        margin-left: 5rem;
        flex: 1 1 100%;
    }

    .container {
        display: flex;
        flex: 1 1 100%;
    }

    @media only screen and (max-width: $tablet) {
        h1,
        h2 {
            font-size: 36px;
        }
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: white;
}

body::-webkit-scrollbar-thumb {
    background: $bg-secondary;
}

.text-muted {
    color: #6c757d !important;
}

// Button Styles
.btn-primary {
    display: flex;
    background-color: white;
    font-family: "Poppins";
    font-size: 1.125rem;
    font-weight: 500;
    color: black;
    padding: 0.75rem 3.375rem;
    transition: all 0.15s ease-in-out;
    text-transform: uppercase;

    &:hover {
        background-color: black;
        color: white;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }

    &:disabled {
        filter: grayscale(100%) opacity(0.7);
        color: $text-primary;
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.btn-link {
    background-color: transparent;
    font-family: "Poppins";
    border: unset;
    color: $text-secondary;
    font-weight: 700;

    &:hover,
    &:active {
        color: #04314d;
    }
}
