@import "../../includes/settings.scss";

.admin-container {
    flex: 1 1 100%;
    margin-left: 5rem;

    .sidebar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    padding: 0;
                    justify-content: flex-start;
                }
            }
        }
        &:hover {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        padding: 0 2rem;
                    }
                }
            }
        }
    }
    button {
        border-radius: unset;
        background-color: unset;

        &:hover {
            background-color: unset;
            border-color: unset;
        }
    }
    h1 {
        color: $text-dark;
    }
    .dg-accordian-item {
        .header {
            background-color: $text-dark;
        }
        .body {
            h3,
            p {
                color: unset;
            }
            h3 {
                padding-right: 2rem;
            }
        }
    }

    .nav-link {
        &:hover {
            background: unset;
        }
    }
    .dg-container {
        .login-container {
            min-height: unset;
            padding: unset;
            box-shadow: unset;
            border: unset;

            .content {
                border: 1px solid rgba(0, 0, 0, 0.125);

                label {
                    font-size: 16px;
                    color: black;
                }
                img {
                    max-width: 100%;
                    max-height: 400px;
                    object-fit: contain;
                }
                .toggle {
                    margin: 0 7.5px;

                    label {
                        text-align: left;
                    }
                }
                .col {
                    label {
                        display: none;
                    }
                }
            }
            .header-container {
                .header {
                    height: unset;
                    align-content: center;
                    background: unset;
                }
            }
        }
    }
    .field-group {
        justify-content: center;
        flex-basis:90%;
        .btn-primary {
            background-color: white;
            flex: 0 1 auto;
        }
        .form-input-container {
            flex: 1 1 100%;
        }
    }
    main {
        margin-left: 0;
        .blogEditor {
            .container-row {
                margin-top: unset;
            }

            .WYSIWUG_Editor {
                flex: 1 1 100%;

                .wrapper-class {
                    width: 100%;
                }

                .toolbar-class {
                    width: 100%;
                }

                .editor-class {
                    width: 100%;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .admin-container {
        margin-left: 0;

        .dg-container {
            .login-container {
            }
        }
    }
}
