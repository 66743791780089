@import "../../includes/settings.scss";

.container {
    .inner-column {
        flex: 0 1 50%;
    }
}
.fees-container {
    scroll-margin-top: 5rem;
    flex: 1 1 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: auto;
    flex-direction: column;
    overflow: hidden;

    .content-container {
        flex-direction: column;
        padding: 7.25rem 0;

        .title-container {
            flex: 1 1 100%;
            justify-content: center;
            align-items: center;
            margin: 0 1rem;

            h2 {
                font-size: 1.5rem;
                text-align: center;
            }
        }

        .fees-table-container {
            justify-content: center;

            table {
                display: flex;
                justify-content: center;
                flex: 1 1 auto;
                border-spacing: 0;
                max-width: 34.375rem;
            }

            td {
                font-weight: 700;
                font-size: 1.125rem;
                padding: 1.75rem 2.25rem 0.75rem 2.25rem;
                border-bottom: solid 3px $text-primary;
            }
            .left {
                text-align: left;
                font-weight: 400;
                max-width: 28rem;
            }

            .right {
                text-align: right;
            }

            @media only screen and (max-width: $tablet) {
                table {
                    margin: 0 1.125rem;
                }
            }
        }

        .button-container {
            flex: 1 1 100%;
            justify-content: center;
            padding-top: 3rem;
        }
    }
}

@media only screen and (max-width: $tablet-lrg) {
    .fees-container {
        .content-container {
            padding: 4.625rem 0;

            .fees-table-container {
                td {
                    font-size: 1rem;
                    padding: 1.125rem 0.5rem 0.5rem 0.5rem;
                }
            }
            .button-container {
                padding-top: 1.5rem;
            }
        }
    }
}
/* Small screens */
@media only screen and (max-width: 1024px) {
    .container {
        .inner-column {
            flex: 1 1 100%;
        }
    }
}
