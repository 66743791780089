.contact-icons-container {
    ul {
        list-style: none;
        display: flex;
    }

    .contact-icon {
        &:hover {
            .red-dot {
                opacity: 1;
            }
        }

        .icon-image {
            position: absolute;
            top: 0.2rem;
            left: 0.45rem;
            transition: all 0.2s ease-in-out;

            .contact-svg-primary {
                fill: #04314d;
            }

            .contact-svg-secondary {
                fill: #ffffff;
            }
        }

        .red-dot {
            position: relative;
            z-index: -5;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }
}
