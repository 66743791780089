@import '../../includes/settings.scss';

.map-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;

    div {
        flex: unset;
    }
    .address-container {
        position: absolute;
        width: clamp(calc(300px - (50px * 2)), 100%, calc(400px - (50px * 2)));
        height: auto;
        top: 25px;
        right: 25px;
        z-index: $zIndex-content-overlay;
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        background-color: $bg-primary;
        justify-content: flex-start;
        padding: 40px 30px;

        .address {
            display: flex;
            flex-direction: column;
            flex: 0 1 auto;
            color: $text-light;

            h2, p {
                padding: 0 0 $spacingParagraph 0;
            }

            h2 {
                line-height: 34px;
                font-size: 30px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .map {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;

        .google-map-container {
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;

            .google-map-element {
                display: flex;
                flex-direction: row;
                flex: 1 1 100%;
            }
        }
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .map-container {
        .address-container {
            justify-content: center;
            text-align: center;
            position: relative;
            top: unset;
            right: unset;
            z-index: unset;
        }
        .map {
            min-height: 80vh;
        }
    }
}

