.background-image-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1000;

    .image-container {
        picture {
            flex: auto;
        }

        img {
            object-fit: cover;
            height: 100%;
            //margin-bottom: 50%;
            //transform: translateY(-50%);
        }
    }
}
