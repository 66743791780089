@import "../../includes/settings.scss";

.gallery-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    flex-wrap: nowrap;
    max-width: 120rem;

    .left,
    .right {
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;

        .gallery-button {
            height: 50px;
            width: 50px;

            img {
                height: 100%;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        justify-content: space-evenly;

        .slide {
            flex: 0 1 32%;
        }

        img {
            max-height: 350px;
        }
    }
}

@media only screen and (max-width: $tablet-lrg) {
    .gallery-container {
        .body {
            .slide {
                flex: unset;
            }
        }
    }
}
