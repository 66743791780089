@import "../../includes/settings.scss";

.card-container {
    display: flex;
    flex: 0 1 50%;
    flex-direction: row;
    align-items: center;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem #8C8C8C;
    
    .card-title {
        flex: 1 1 100%;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        height: 5rem;
        background: $bg-secondary;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .card-body {
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        color: black;
    }

    .left {
        flex: 1 1 100%;
    }

    .right {
        flex: 1 1 100%;
        justify-content: center;
    }

}
